import { Span, Text } from "@clipboard-health/ui-react";
import { formatDollarsAsUsd } from "@src/appV2/lib/Money/utils/currency";

import { type Shift } from "../Shift/types";

interface ShiftPayContentProps {
  shift: Shift;
}

export function ShiftPayContent(props: ShiftPayContentProps) {
  const { shift } = props;

  return (
    <Text variant="body2" color="inherit">
      {shift.isInstantPay
        ? formatDollarsAsUsd(shift.instantPayDetails?.totalAmount ?? 0)
        : formatDollarsAsUsd((shift.finalPay ?? 0) * (shift.time ?? 0))}{" "}
      total
      {shift.isInstantPay ? (
        <>
          {" | "}
          {shift.instantPayDetails?.is100InstantPayEnabled
            ? ""
            : `${formatDollarsAsUsd(shift.instantPayDetails?.paidAmount ?? 0)}`}
          <Span sx={{ color: (theme) => theme.palette.success.main }}>
            {" "}
            <b>InstantPay</b>
          </Span>
        </>
      ) : null}
    </Text>
  );
}
