import { post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";

import {
  type SetShiftTimesheetAvailabilityRequest,
  setShiftTimesheetAvailabilityRequestSchema,
  type SetShiftTimesheetAvailabilityResponse,
  setShiftTimesheetAvailabilityResponseSchema,
} from "../types";
import { GET_AGENT_UNVERIFIED_SHIFTS_PATH } from "./useAgentUnverifiedShifts";

export const SET_TIMESHEET_AVAILABILITY_URL = "/v1/shifts/setTimeSheetAvailability";

export function useSetShiftTimesheetAvailability(
  options: UseMutationOptions<
    SetShiftTimesheetAvailabilityResponse,
    AxiosError,
    SetShiftTimesheetAvailabilityRequest
  > = {}
): UseMutationResult<
  SetShiftTimesheetAvailabilityResponse,
  AxiosError,
  SetShiftTimesheetAvailabilityRequest
> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params) => {
      const response = await post({
        url: SET_TIMESHEET_AVAILABILITY_URL,
        data: params,
        requestSchema: setShiftTimesheetAvailabilityRequestSchema,
        responseSchema: setShiftTimesheetAvailabilityResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.SET_TIMESHEET_AVAILABILITY_FAILURE,
    },
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries({
        queryKey: [
          `${environmentConfig.REACT_APP_BASE_API_URL}${GET_AGENT_UNVERIFIED_SHIFTS_PATH}`,
        ],
      });
      return options.onSuccess?.(data, variables, context);
    },
  });
}
