import { formatShortDateWithWeekday } from "@clipboard-health/date-time";
import { Text, useModalState } from "@clipboard-health/ui-react";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/WarningAmberOutlined";
import { Button, Card, CardActions, CardContent, IconButton, Stack } from "@mui/material";
import { CardAlert } from "@src/appV2/lib/Alert/CardAlert";
import { type UnverifiedShift } from "@src/appV2/Shifts/UnverifiedShifts/types";

import { DEFAULT_TIMEZONE } from "../Shift/constants";
import { NonInstantPayShiftDialog } from "./modals/NonInstantPayShiftDialog";
import { UnverifiedShiftDetails } from "./UnverifiedShiftDetails";

interface UnverifiedShiftCardProps {
  shift: UnverifiedShift;
  isShiftVerificationActive: boolean;
  onUploadTimesheetClick: () => void;
  onReuploadTimesheetClick: () => void;
  onMoreOptionsClick: () => void;
}

export function UnverifiedShiftCard(props: UnverifiedShiftCardProps) {
  const {
    shift,
    isShiftVerificationActive,
    onUploadTimesheetClick,
    onReuploadTimesheetClick,
    onMoreOptionsClick,
  } = props;

  const timezone = shift.facility.tmz ?? DEFAULT_TIMEZONE;
  const timesheetArrayLength = shift?.timecard?.files?.length ?? 0;
  const timeSheetExists = timesheetArrayLength > 0 || shift?.timecardNotAvailable?.reason;

  const canUploadTimesheet =
    (shift.facility.verificationPreference?.usesTimesheets ?? false) ||
    shift.facility.requireTimecardPhoto;

  const nonInstantPayModalState = useModalState();

  return (
    <Card elevation={3} data-testid="unverified-shift-card">
      <CardContent>
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Text bold>{formatShortDateWithWeekday(shift.start, { timeZone: timezone })}</Text>
            {!timeSheetExists &&
            isShiftVerificationActive &&
            (shift?.facility?.verificationPreference?.usesTimesheets ??
              shift.facility?.requireTimecardPhoto) ? (
              <CardAlert
                IconComponent={() => <WarningIcon color="error" />}
                label="Missing timesheet"
                severity="error"
              />
            ) : null}
          </Stack>
          <UnverifiedShiftDetails shift={shift} />
          {shift.isChangedToNonInstantPay ? (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Text variant="subtitle2">This shift is no longer InstantPay-eligible.</Text>
              <IconButton
                aria-label="Non-InstantPay info"
                onClick={nonInstantPayModalState.openModal}
              >
                <InfoIcon />
              </IconButton>
            </Stack>
          ) : null}
        </Stack>
        <NonInstantPayShiftDialog
          modalState={nonInstantPayModalState}
          userHasForgottenToClockOut={shift.autoClockedOut}
        />
      </CardContent>
      {canUploadTimesheet && isShiftVerificationActive ? (
        <CardActions>
          <Stack width="100%" paddingX={2}>
            {(shift?.timecard?.files?.length ?? 0) > 0 || shift?.timecardNotAvailable?.reason ? (
              <Button onClick={onReuploadTimesheetClick}>Upload new timesheet</Button>
            ) : (
              <>
                <Button disableElevation variant="contained" onClick={onUploadTimesheetClick}>
                  Upload timesheet
                </Button>
                <Button onClick={onMoreOptionsClick}>More options</Button>
              </>
            )}
          </Stack>
        </CardActions>
      ) : null}
    </Card>
  );
}
