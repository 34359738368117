import { InternalLink, Text } from "@clipboard-health/ui-react";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { generatePath } from "react-router-dom";

import { ShiftTimeLabel } from "../Shift/ShiftTimeLabel";
import { type Shift } from "../Shift/types";
import { ShiftPayContent } from "./ShiftPayContent";

interface UnverifiedShiftDetailsProps {
  shift: Shift;
}

export function UnverifiedShiftDetails(props: UnverifiedShiftDetailsProps) {
  const {
    shift,
    shift: { facility },
  } = props;

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={4} alignItems="center">
        <LocationOnIcon htmlColor="#0000008F" />
        <Text variant="body2" color="inherit">
          {facility.userId ? (
            <InternalLink
              to={generatePath(DeprecatedGlobalAppV1Paths.FACILITY_DETAIL, {
                facilityId: facility.userId,
              })}
            >
              {facility.name}
            </InternalLink>
          ) : (
            facility.name
          )}
        </Text>
      </Stack>
      <Stack direction="row" spacing={4} alignItems="center">
        <AccessTimeFilledIcon htmlColor="#0000008F" />
        <ShiftTimeLabel
          shiftStart={shift.start}
          shiftEnd={shift.end}
          timeZone={shift.facility.tmz}
          shiftDuration={shift.time}
        />
      </Stack>
      <Stack direction="row" spacing={4} alignItems="center">
        <AttachMoneyIcon htmlColor="#0000008F" />

        <ShiftPayContent shift={shift} />
      </Stack>
    </Stack>
  );
}
