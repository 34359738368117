import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { ExternalLink } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib";
import { SupportArticleLinks } from "@src/appV2/support/constants";

interface WorkedBreakPayRequestInfoDialogProps {
  modalState: UseModalState;
  approvalDeadlineLabel: string;
}

export function WorkedBreakPayRequestInfoDialog(props: WorkedBreakPayRequestInfoDialogProps) {
  const { modalState, approvalDeadlineLabel } = props;

  return (
    <CommonDialog
      modalState={modalState}
      title="Additional break payment is pending facility approval."
      actions={
        <Button
          onClick={() => {
            modalState.closeModal();
          }}
        >
          Ok
        </Button>
      }
    >
      <Stack spacing={1}>
        <Text>
          This shift contains skipped break time even though a 30-minute unpaid break is mandatory
          at this facility. The additional break payment is pending facility approval by{" "}
          {approvalDeadlineLabel}.
        </Text>
        <Text>
          If you have any questions, please visit the{" "}
          <ExternalLink to={SupportArticleLinks.EXTRA_WORK_BREAKS}>Help Center</ExternalLink>.
        </Text>
      </Stack>
    </CommonDialog>
  );
}
