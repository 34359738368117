import { isDefined } from "@clipboard-health/util-ts";
import { IonPage } from "@ionic/react";
import { Alert, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { parseISO } from "date-fns";

import { AppBarHeader, BackButtonLink, PageWithHeader } from "../../lib";
import {
  type ExtraWorkedTimeRequestEntity,
  useExtraWorkedTimeRequests,
} from "../MandatoryBreakPolicy/api/useExtraWorkedTimeRequests";
import { useAgentUnverifiedShifts } from "./api/useAgentUnverifiedShifts";
import { UnverifiedShiftCardSkeleton } from "./UnverifiedShiftCardSkeleton";
import { UnverifiedShiftItem } from "./UnverifiedShiftItem";
import { WorkedBreakPayRequestCard } from "./WorkedBreakPayRequestCard";

function isExtraWorkedTimeRequests(item?: any): item is ExtraWorkedTimeRequestEntity {
  return item?.type === "ExtraWorkedTimeRequest";
}

export function UnverifiedShiftsPage() {
  const {
    data: unverifiedShiftsData,
    isLoading: isLoadingUnverifiedShifts,
    isSuccess: isSuccessUnverifiedShifts,
    isError,
  } = useAgentUnverifiedShifts();

  const {
    isLoading: isLoadingExtraWorkedTimeRequests,
    data: extraWorkedTimeRequestsData,
    isSuccess: isSuccessExtraWorkedTimeRequests,
  } = useExtraWorkedTimeRequests();

  const isSuccess = isSuccessUnverifiedShifts && isSuccessExtraWorkedTimeRequests;
  const isLoading = isLoadingUnverifiedShifts || isLoadingExtraWorkedTimeRequests;

  const items = [
    ...(extraWorkedTimeRequestsData?.data ?? []),
    ...(unverifiedShiftsData?.response ?? []),
  ].sort((itemA, itemB) => {
    let startA: string;
    let startB: string;

    if (isExtraWorkedTimeRequests(itemA)) {
      const shift = extraWorkedTimeRequestsData?.included.find(
        (shiftData) => shiftData.id === itemA.attributes.shiftId
      );
      startA = shift?.type === "shift" ? shift.attributes.start : "";
    } else {
      startA = itemA.start;
    }

    if (isExtraWorkedTimeRequests(itemB)) {
      const shift = extraWorkedTimeRequestsData?.included.find(
        (shiftData) => shiftData.id === itemB.attributes.shiftId
      );
      startB = shift?.type === "shift" ? shift.attributes.start : "";
    } else {
      startB = itemB.start;
    }

    return startB?.localeCompare(startA);
  });

  return (
    <IonPage>
      <PageWithHeader
        containerMaxWidth="md"
        appBarHeader={
          <AppBarHeader
            title="Unverified Shifts"
            leftCta={
              <BackButtonLink
                alwaysUseDefaultBackTo
                defaultBackTo={DeprecatedGlobalAppV1Paths.MY_SHIFTS}
              />
            }
          />
        }
      >
        <Stack spacing={2}>
          {isLoading && <UnverifiedShiftCardSkeleton />}
          {isError && <Alert severity="error">Error loading unverified shifts.</Alert>}
          {isSuccess ? (
            <Stack spacing={2}>
              {items.map((item) => {
                if (isExtraWorkedTimeRequests(item)) {
                  const shift = extraWorkedTimeRequestsData?.included.find(
                    (shift) => shift.id === item.attributes.shiftId
                  );

                  if (!isDefined(shift) || shift.type !== "shift") {
                    return null;
                  }

                  return (
                    <WorkedBreakPayRequestCard
                      key={item.id}
                      shiftStart={shift.attributes.start}
                      shiftEnd={shift.attributes.end}
                      timeZone={shift.attributes.facility.tmz}
                      facilityUserId={item.attributes.facilityId}
                      facilityName={shift.attributes.facility.name}
                      breakPay={item.meta.computedPay}
                      approvalDeadline={parseISO(item.meta.computedExpirationTime)}
                    />
                  );
                }

                return <UnverifiedShiftItem key={item._id} shift={item} />;
              })}
            </Stack>
          ) : null}
        </Stack>
      </PageWithHeader>
    </IonPage>
  );
}
