import { Text } from "@clipboard-health/ui-react";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Skeleton, Stack } from "@mui/material";

export function UnverifiedShiftDetailsSkeleton() {
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={4} alignItems="center">
        <LocationOnIcon htmlColor="#0000008F" />
        <Text variant="body2" color="inherit">
          <Skeleton width={200} />
        </Text>
      </Stack>
      <Stack direction="row" spacing={4} alignItems="center">
        <AccessTimeFilledIcon htmlColor="#0000008F" />
        <Text variant="body2" color="inherit">
          <Skeleton width={200} />
        </Text>
      </Stack>
      <Stack direction="row" spacing={4} alignItems="center">
        <AttachMoneyIcon htmlColor="#0000008F" />

        <Text variant="body2" color="inherit">
          <Skeleton width={200} />
        </Text>
      </Stack>
    </Stack>
  );
}
