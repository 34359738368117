import { Card, CardContent, Skeleton, Stack } from "@mui/material";

import { UnverifiedShiftDetailsSkeleton } from "./UnverifiedShiftDetailsSkeleton";

export function UnverifiedShiftCardSkeleton() {
  return (
    <Card elevation={3}>
      <CardContent>
        <Stack spacing={1}>
          <Skeleton width={150} />
          <UnverifiedShiftDetailsSkeleton />
        </Stack>
      </CardContent>
    </Card>
  );
}
